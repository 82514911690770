import { defineStore } from 'pinia'
import {
	CONTRACT_STATES,
	CONTRACT_TYPES,
	ORDER_STATES, ORDER_STATES_IGNORE, ORDER_STATES_IN_QUEUE,
	ORDER_STATES_INACTIVE,
	ORDER_TYPES
} from '~/store/constants/order';
import {KEY_CONTRACT, KEY_ORDER} from '~/store/constants/keys';
import {useUserStore} from '~/stores/user';
import {useAccountStore} from '~/stores/account';
import {ContractApi, OrderApi, type OrdersDto} from '~/gen/openapi/portalService';

const ACCOUNT_TYPES = {
	OWNER: 'owner',
	CONTRACTOR: 'contractor',
} as const


interface State {
	contracts: any[],
	contractsUnsigned: any[],
	contractStates: any[],
	contractTypes: any[],
	isFetchingContracts: boolean,
	isFetchingOrders: boolean,
	lastFetchAccount: string | null,
	orders: OrdersDto[],
	orderTypes: typeof ORDER_TYPES,
	orderTypesIgonre: number[],
	orderStates: any[],
	statusesInActive: number[],
	statusesIgnores: number[],
	timeLastFetch: number | null,
	accountType: string | null,
}

export const useOrdersStore = defineStore('orders', {
  state: (): State => ({
		contracts: [],
		contractsUnsigned: [],
		contractStates: CONTRACT_STATES,
		contractTypes: CONTRACT_TYPES,
		isFetchingContracts: false,
		isFetchingOrders: false,
		lastFetchAccount: null,
		orders: [],
		orderTypes: ORDER_TYPES,
		orderTypesIgonre: [6, 11], // Array of order type Ids to ignore
		orderStates: ORDER_STATES,
		statusesInActive: ORDER_STATES_INACTIVE, // Array of inactive statuses
		statusesIgnores: ORDER_STATES_IGNORE, // Array og statuses to ignore
		timeLastFetch: null,
		accountType: null,
	}),
	getters: {
		active(): OrdersDto[] {
			const activeContractStates = CONTRACT_STATES.filter(
				(type) => type.Active === true
			).map((type) => type.Id)
			const inactiveOrderStates = this.statusesInActive
			const statusesIgnores = this.statusesIgnores

			return this.combined
				.filter((order: OrdersDto) => {
					return this.isContract(order)
						// @ts-ignore
						? activeContractStates.includes(order.Status!) && !order.CompletedDate
						: !inactiveOrderStates.includes(order.Status!) &&
						!statusesIgnores.includes(order.Status!)
				})
				.sort((a, b) => new Date(a.DeliveryDate!).getTime() - new Date(b.DeliveryDate!).getTime())
		},
		combined(): OrdersDto[] {
			return this.filtered.concat(this.contractsModified)
		},
		contractsModified(): OrdersDto[] {
			return (
				(this.contracts &&
					Array.isArray(this.contracts) &&
					this.contracts.map((contract) => {
						const contractType =
							this.getContractType(contract.ContractType) || ''

						return Object.assign(
							{
								CompletedDate: contract.CompletedDate || null,
								DeliveryDate: new Date(
									contract.DeliveryYear,
									contract.DeliveryMonth - 1,
									15,
									12
								).toJSON(),
								Name: (contractType && contractType.Name) || '',
								OrderNumber: contract.ContractNumber,
								InternalNumber: contract.InternalNumber || 0,
								Type: `FELLING_${contract.ContractType}`,
							},
							contract
						)
					})) ||
				[]
			)
		},
		filtered(state: State) {
			return state.orders && state.orders.length
				? state.orders.filter(
					(order) => !state.orderTypesIgonre.includes(order.Type!)
				)
				: []
		},
		getById(): (orderId?: string) => OrdersDto | undefined {
			return (orderId?: string) => {
				return this.combined.find((order) => order.Id === orderId)
			}
		},
		getContractById(): (contractId: string) => any {
			return (contractId: string) => {
				const allContracts = this.contracts.concat(this.contractsUnsigned)

				return allContracts.find((contract) => contract.Id === contractId)
			}
		},
		getContractType(): (typeId: number) => any {
			return (typeId) => {
				return (
					CONTRACT_TYPES.find((contractType) => contractType.Id === typeId) || {
						Name: 'Ukjent kontrakttype',
						Icon: 'ot-none',
					}
				)
			}
		},
		getPath(): (order: OrdersDto) => string {
			return (order) => {
				const isContract = this.isContract(order)
				const prefix = isContract ? KEY_CONTRACT : KEY_ORDER
				return `${prefix}${order.Id}`
			}
		},
		getType(): (typeId: number) => any {
			return (typeId) => {
				return (
					ORDER_TYPES.find((orderType) => orderType.Id === typeId) || {
						Name: 'Ukjent ordretype',
						Icon: 'ot-none',
					}
				)
			}
		},
		getState(): (stateId: string) => any {
			return (stateId: string) => {
				return this.orderStates.find((state) => state.Id === stateId)
			}
		},
		inactive(): OrdersDto[] {
			const inActiveContractStates = CONTRACT_STATES.filter(
				(type) => type.Active === false
			).map((type) => type.Id)
			// Return all orders that are not active, and don't have statuses that makes them hidden from the user
			return this.combined
				.filter((order) => {
					return this.isContract(order)
						// @ts-ignore
						? inActiveContractStates.includes(order.Status!) || order.CompletedDate
						: order.hasOwnProperty('DeliveryDate') &&
						order.DeliveryDate != null &&
						this.statusesInActive.indexOf(order.Status!) !== -1 &&
						this.statusesIgnores.indexOf(order.Status!) === -1
				})
				.sort((a, b) => {
					return new Date(b.DeliveryDate!).getTime() - new Date(a.DeliveryDate!).getTime()
				})
		},
		inQueue(state) {
			return state.orders.filter((order) =>
				ORDER_STATES_IN_QUEUE.includes(order.Status!)
			)
		},
		isContract(): (order: OrdersDto) => boolean {
			return (order) => {
				return order.Type?.toString().substring(0, 7) === 'FELLING'
			}
		},
		isContractByPath(): (path?: string) => boolean {
			return (path) => {
				return path?.charAt(0) === KEY_CONTRACT
			}
		},
		isFetching(state) {
			return state.isFetchingOrders || state.isFetchingContracts
		},
		isSignedContract(): (contractId: string) => boolean {
			return (contractId) => {
				return (
					this.contractsUnsigned.findIndex(
						(contract) => contract.Id === contractId
					) === -1
				)
			}
		},
	},
	actions: {
		// originally a mutation in vuex
		updateOrder(updatedOrder: OrdersDto) {
			if (!this.orders || this.orders.length === 0) {
				return
			}
			const orderIndex = this.orders.findIndex(
				(order) => order.Id === updatedOrder.Id
			)
			if (orderIndex > -1) {
				this.orders = [
					...this.orders.slice(0, orderIndex),
					updatedOrder,
					...this.orders.slice(orderIndex + 1),
				]
			}
		},

		fetch(options?: { type?: string }) {
			const type =
				options &&
				options.type &&
					// @ts-ignore
				Object.values(ACCOUNT_TYPES).includes(options.type)
					? options.type
					: ACCOUNT_TYPES.OWNER
			const userStore = useUserStore()
			const userId = userStore.userId
			const activeAccount = useAccountStore().activeAccount

			if (!activeAccount) {
				throw new Error('No active account')
			}


			// Only fetch if we have a valid user id and if it's more than 1min (60 * 1000) since last fetch
			if (
				!userId ||
				(this.accountType === type &&
					this.lastFetchAccount === activeAccount &&
					(this.orders.length || this.contracts.length) &&
					this.timeLastFetch &&
					this.timeLastFetch > Date.now() - 60000)
			) {
				this.isFetchingContracts = false
				this.isFetchingOrders = false
				return
			} else {
				this.reset()
			}
			this.lastFetchAccount = activeAccount
			this.timeLastFetch = Date.now()
			this.fetchContracts()
			this.fetchOrders(type)
		},
		fetchContracts() {
			const accountId = useAccountStore().activeAccount
			if (!accountId) {
				return
			}
			const { $config, $axios } = useNuxtApp()
			const contractApi = new ContractApi(undefined, $config.public.apiBaseHost, $axios)
			this.isFetchingContracts = true

			// Fetching the felling orders (contracts)
			contractApi.contractGetTimberContractsByAccount(accountId)
				.then((response) => {
					if (response !== null && typeof response === 'object') {
						this.contracts = response.data
					} else {
						console.error('Wrong contract format')
					}
				})
				.catch((error) => {})
				.finally(() => {
					this.isFetchingContracts = false
				})

			// Fetching the unsigned felling orders (contracts)
			contractApi.contractGetUnsignedContractsByAccount(accountId)
				.then((response) => {
					this.contractsUnsigned = response.data
				})
				.catch((error) => {})
		},
		fetchOrders(type: string) {
			const accountId = useAccountStore().activeAccount
			if (!accountId) {
				return
			}

			const { $config, $axios } = useNuxtApp()
			const ordersApi = new OrderApi(undefined, $config.public.apiBaseHost, $axios)



			const promise =
				type === ACCOUNT_TYPES.CONTRACTOR
					? ordersApi.orderGetOrdersByEntrepreneurAccount(accountId)
					: ordersApi.orderGetOrdersByAccount(accountId)
			this.isFetchingOrders = true

			// Fetching all but the felling orders
			promise
				.then(({data: orders}) => {
					if (orders !== null && typeof orders === 'object') {
						this.orders = orders
						this.accountType = type
					} else {
						console.error('Wrong order format')
					}
				})
				.catch((error) => {})
				.finally(() => {
					this.isFetchingOrders = false
				})
		},
		reset() {
			this.timeLastFetch = null
			this.contracts = []
			this.orders = []
			this.accountType = null
			this.contractsUnsigned = []
		},
		resetFetchingTime() {
			this.timeLastFetch = null
		},
	}
})
